
.footer {
	margin-top: auto;
	padding: 15px 0 15px;
	background-color: rgb(73, 62, 53);
    color: aliceblue;
}

.footer__wrapper {
	display: grid;
	grid-template-rows: repeat(2);
	flex-direction: column;
	align-items: center;
	padding: 0 30px;
}

.social {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;
	padding: 10px;
}
@media only screen and (max-width: 760px) {
	.social {
		display: block;
		padding: 20px 0;
	}
  }

  .social__header {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;
	padding: 10px;
}

.social__header-item:first-child {
	max-width: 220px;
}
@media only screen and (max-width: 760px) {
	.social__header {
		display: block;
		padding: 20px 0;
	}
  }
.social__item {
	font-size: 12px;
}


.contact__item {
	display: flex;
	align-items: center;
    column-gap: 10px;
	padding: 10px 0;
}

.contact__icon {
	/* width: 20px;
	height: 20px; */
	font-size: 16px;
}
.copyright {
	font-size: 16px;
	font-family: "Space grotesk";
}

.copyright a {
	text-decoration: none;
	color: #FFFCF2;
}

.copyright a + a {
	margin-top: 0.5em;
}

.info__text {
	padding: 10px 0;
}
.info__img {
	width: 150px;
}

.contact__icon-wrapper {
	height: 16px;
}

/* FIX FIX */


.item {
	margin: 20px 0;
    row-gap: 40px;
    display: flex;
    flex-direction: column;
}


.email__footer {
	word-break: break-word;
}