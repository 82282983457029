
.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(54, 53, 53, 0.534);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal__content {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    /* margin: 10px 25px; */
    background-color: rgba(255, 255, 255, 0.69);
    transition: 0.4s;
    transform: translateY(-150%);
    
}

.modal__content.active {
    transform: translateY(0);

}

.modal__children {
    padding: 35px 10px;
    opacity: 0;
}

.modal__children.active {
    transition: .9s;
    opacity: 1;
}