
.navbar {
    position: fixed;
    top: 0;
    width: calc(100% - 20px);
    left: 10px;
    margin-top: 10px;
    z-index: 200;
}
.navbar__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rgb(194, 188, 154); */
    background-color: #ffffff;
    padding: 15px;
}
.nav__logo {
    /* font-size: 16px; */
    /* padding: 0 10px; */
    width: 100px;
    /* height: 25px; */
}

.nav__link {
    display: flex;
    align-items: center;
}
.nav__link-main {
    /* font-size: 18px; */
    padding: 0 10px;
}

.nav__link-aside {
    display: flex;
}
.authModal__content {
    position: absolute;
    margin: 10px 25px;
    right: 0;
    top: 0;
}
.authModal__content.active {
    /* transform: scaleY(1); */
    /* height: 400px; */
    opacity: 1;

}
.authModal__form {
    display: flex;
    flex-direction: column;
}


@media (max-width: 1024px) {
    .nav__link-main {
      display: none;
    }
  }