.cart-modal {
    max-height: 70vh;
    overflow: auto;
}

.cart__modal-list {
    /* width: 500px; */
}

.cart__modal-main{
    padding: 10px;
    width: calc(100% -25px);
    max-height: 120px;
    margin: 5px;
    display: flex;
}
.cart__modal-img {
    object-fit: cover;
    width: 60px;
    height: 90px;
    padding-right: 5px;
}