.card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 20px 20px;
}

.card__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* max-width: 120px; */
}

.card__container img {
    border-radius: 10px;
}

.gallery-mode {
    margin: 0;
    margin-top: 0.2px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.gallery-mode-card {
    display: grid;
    grid-template-columns: repeat(4, minmax(40px, 1fr));
}

.gallery-mode img {
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 0;
    outline: 0.5px solid black;
}

.gallery-mode .card__img-container {
    width: auto;
    height: auto;
}

.card__list-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: .5s;
}

.card__img-container {
    display: flex;
    justify-content: center;
    width: 250px;
    height: 380px;
}

@media only screen and (max-width: 768px) {
    .card__img-container {
        min-width: calc(100% - 20px);
        /* margin: 10px; */
    }
}

@media only screen and (max-width: 620px) {
    .card__img-container {
        width: 180px;
        height: 290px;
        /* минимальная ширина уже учтена в предыдущем медиа-запросе */
    }

    .card__title {
        max-width: 175px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

@media only screen and (max-width: 440px) {
    .card__img-container {
        width: 120px;
        height: 220px;
    }

    .card__img-container.gallery-mode {
        width: 80px;
        height: 120px;
    }

    .card__title {
        max-width: 119px;
    }
    .card__info-container {
        max-width: 119px;
    }
}

.card__menu {
    display: flex;
    justify-content: space-between;
    transition: .5s;
}

.card__container button {
    display: flex;
}

/* .card__container button {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 10; 
} */
