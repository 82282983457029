@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant&display=swap');

@font-face {
  font-family: 'Mont-Light';
  src: local('Mont-Light'),
  url(./fonts/Mont-Light.ttf);
}

/* RESET */

/* #region RESET */

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}



/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}


h1,
h2,
h3,
h4 {
  font-family: 'Cormorant', serif;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Mont-Light';
  background-color: #F6EFDF;
  /* overflow-x: hidden; */
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

/* #endregion RESET */


.text {
  background-color: #EFE5BF;
  padding-bottom: 60px;
}

/* HOME */

/* #region HOME */

.home {
  margin-top: 80px;
}

.home__container {
  height: 100vh;
  /* width: 100vw; */
}

.header__images {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: 30% 35% 30%; 
  z-index: -10;
  height: 100%;
  width: 100%;
  
}

/* #endregion HOME */



/* HEADER IMAGES */

/* #region HEADER_IMAGES */

.scrollnimate {
  transform: translateY(-20px) scale(1);
  transition: opacity 0.5s ease, transform 0.5s ease;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: .5s; /* Продолжительность анимации */
  animation-fill-mode: forwards;
}

.first__img {
  animation-delay: .7s; /* Задержка */
}

.second__img {
  animation-delay: .8s; /* Задержка */

}
.third__img {
  animation-delay: .5s; /* Задержка */
}
.fourth__img{
  animation-delay: .6s; /* Задержка */
}
.fifth__img{
  animation-delay: .9s; /* Задержка */
}
@keyframes fadeIn {
  from { 
    transform: translateY(-30px) scale(0.5);
    opacity: 0;
  }
  to {  
    transform: translateY(0); 
    transform: scale(1);
    opacity: 1;
  }
 }

.image__main {
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
  padding: 15px;
}


.image__main:nth-of-type(1) {
  grid-column: 1 / span 1; /* Первая ячейка */
  grid-row: 1 / span 1; /* Первая строка */
  transition-delay: 5s;
}
.image__main:nth-of-type(2) {
  grid-column: 2 / span 1; /* Вторая ячейка */
  grid-row: 3 / span 1; /* Первая строка */
  transition-delay: 2s ;
}

.image__main:nth-of-type(3) {
  grid-column: 3 / span 1; 
  grid-row: 2 / span 1; 
  transition-delay: 3s;
}
.image__main:nth-of-type(4) {
  grid-column: 4 / span 1; 
  grid-row: 1 / span 1; 
}
.image__main:nth-of-type(5) {
  grid-column: 5 / span 1; 
  grid-row: 2 / span 1; 
}



@media only screen and (max-width: 767px) {
  .header__images {
    grid-template-columns: repeat(8, 1fr); 
    grid-template-rows: repeat(10, 1fr); 
  }
  .image__main:nth-of-type(1) {
    grid-column: 1 / span 2;
    grid-row: 1 / span 10;
    padding: 10px;
  }
  
  .image__main:nth-of-type(2) {
    grid-column: 2 / span 2;
    grid-row: 7 / span 10;
    padding: 20px;
  }

  .image__main:nth-of-type(3) {
    grid-column: 3 / span 4;
    grid-row: 3 / span 10;
  }

  .image__main:nth-of-type(4) {
    grid-column: 7 / span 2;
    grid-row: 2 / span 10;
  }

  .image__main:nth-of-type(5) {
    grid-column: 6 / span 2;
    grid-row: 7 / span 10;
  }
  
}



@media only screen and (max-width: 430px) {
  .header__images {
    grid-template-columns: repeat(8, 1fr); 
    grid-template-rows: repeat(10, 1fr); 
  }
  .image__main:nth-of-type(1) {
    grid-column: 2 / span 3;
    grid-row: 1 / span 10;
    padding: 10px;
  
  }
  
  .image__main:nth-of-type(2) {
    grid-column: 1 / span 3;
    grid-row: 7 / span 10;
    padding: 20px;
  }

  .image__main:nth-of-type(3) {
    grid-column: 2 / span 6;
    grid-row: 4 / span 10;
  }

  .image__main:nth-of-type(4) {
    grid-column: 6 / span 3;
    grid-row: 2 / span 10;
  }

  .image__main:nth-of-type(5) {
    grid-column: 5 / span 3;
    grid-row: 8 / span 10;
  }
  
}




/* #endregion HEADER_IMAGES */


/* BANNER */


/* #region BANNER */

.banner__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  padding: 40px;
  background-color: #FAF7EF;
}


.banner__img {
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.banner__info {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

@media only screen and (max-width: 910px) {
  .banner__container {
    grid-template-columns: 1fr;
    padding: 60px;
  }
  .banner__img {
    padding: 30px 0;
  }
}


/* #endregion BANNER */



/* CHECKOUT */


/* #region CHECKOUT */

.checkout__wrapper {
  margin-top: 80px;
}

.checkout__container {
  width: 100%;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.checkout__list-item {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  height: auto;
  margin: 10px;
}
.checkout__img-container {
  width: 150px;
}
.checkout__img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.checkout__title {
  margin: 10px;
  width: 150px;
}

.checkout__form {
  display: flex;
  flex-direction: column;
}


/* #endregion CHECKOUT */


/* REGISTRATION */

.registration {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registration__container {
  max-width: 300px;
}

/* SHOP PAGE */


.shop {
  margin-top: 80px;
  min-height: 100vh;
}



/* ABOUT US */

.about {
  margin-top: 80px;
}
.about__head-title {
  text-align: center;
}
.about__first-banner {
  display: grid;
  grid-template-columns: repeat(2, 40%);
  justify-content: center;
  align-items: center;
  padding: 20px;
  column-gap: 30px;
  
}

@media only screen and (max-width: 700px) {
  .about__first-banner {
    display: block;
  }
}

.about__info {
  padding: 20px 0;

}

.about__images {
  padding: 10px;
}

.about__images-main {
  max-width: 100%;
  height: auto;
}



.about__accordion {
  margin-top: 20px;

}

.about__accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.about__accordion-toggle {
  font-size: 20px;
  line-height: 20px;
}

.about__accordion-content {
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  transition: 1s;
}

.open {
  max-height: 1000px;
}

.product__carousel {
  /* display: flex; */
  
}

.product__main {
  /* width: 50%; */
  /* display: grid; */
  /* grid-template-columns: repeat(2, 1fr); */
  margin-top: 80px;
 
}



/* CONTACT */


.contact {
  margin-top: 80px;
}

.contact__container {
  display: flex;
  column-gap: 20px;
  padding: 10px 30px;
}





/* TEST */
.card__border {
  border: 1px solid black;
}

.product__image img {
  object-fit: contain;
  max-width: 450px;
  /* width: 10%; */
}
.product__carousel .slick-slider {
  /* display: flex; */
}
.product__carousel-item .slick-slide{
  max-width: 150px;
}
.product__carousel-item .slick-track {
  /* height: 0; */
}

.btn__gallery {
  width: 25px;
  height: 25px;
}

.btn__gallery svg {
  width: 100%;
  height: 100%;
}

.btn__container {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  /* margin: 20px 0; */
  position: fixed;
  right: 0;
  /* background-color: #F6EFDF; */
}



.success {
  text-align: center;
}

.success-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}


.banner__image img {
  width: 100%; /* Ширина изображения будет 100% от блока */
  height: 100vh; /* Высота изображения будет равна высоте экрана */
  object-fit: cover; /* Это свойство позволит изображению покрыть весь блок, сохраняя пропорции */
  display: block; /* Убирает возможные отступы у изображения */
}