button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}
a {
    text-decoration: none;
    color: inherit;
}

.modal__registration{
    display: flex;
    justify-content: space-between;
}
.nav__link-aside button {
    z-index: 10;
}

.nav__link-aside button:nth-of-type(-n+2) {
    padding-right: 10px;
    z-index: 10;
}

.nav-links {
    transition: transform 0.3s;
    transform: translateX(-100%);
}
.nav-links.active {
    transform: translateX(0);
}
.burger__menu {
    display: flex;
    flex-direction: column;
}

.category-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

@media (max-width: 1024px) {
    .authBtn {
      display: none;
    }
  }