.slider__container{
    padding: 20px;
    background-color: #F6EFDF;
}

.product__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.product__list-item {
    height: 380px;
}

.product__list-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: .5s;
}


.product__img-container {
    display: flex;
    justify-content: center;
    height: 380px;
}

.product__price-basket {
    display: flex;
    justify-content: space-between;
}

.product__container:hover  .product__card-menu {
    opacity: 1;
    transition: .5s;
}

.product__container:hover .product__img {
    transform: scale3d(1.05, 1.05, 1.05);
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transition: .5s;
}